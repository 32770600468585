//https://www.thebalance.com/home-amenities-features-home-buyers-1798249

//ok
export const TypesOfLocations = (obj = {}) => ({
  ViewOfCoast: obj.ViewOfCoast || false,
  ViewOfCity: obj.ViewOfCity || false,
  ViewOfHills: obj.ViewOfHills || false,
  WaterfrontRiver: obj.WaterfrontRiver || false,
  WaterfrontOcean: obj.WaterfrontOcean || false,
  WaterfrontLakefront: obj.WaterfrontLakefront || false,
  Greenbelt: obj.Greenbelt || false,
  GolfCourse: obj.GolfCourse || false,
  Suburban: obj.Suburban || false,
  City: obj.City || false,
  RaisedElevation: obj.RaisedElevation || false,
  RasedMountains: obj.RasedMountains || false,
  CulDeSac: obj.CulDeSac || false,
  DeadEndStreet: obj.DeadEndStreet || false,
  GatedCommunity: obj.GatedCommunity || false,
})

//ok
export const HomeConstruction = (obj = {}) => ({
  Adobe: obj.Adobe || false,
  Brick: obj.Brick || false,
  ConcreteBlock: obj.ConcreteBlock || false,
  Log: obj.Log || false,
  Metal: obj.Metal || false,
  Stone: obj.Stone || false,
  Straw: obj.Straw || false,
  Wood: obj.Wood || false,
})

//ok
export const HomeExterior = (obj = {}) => ({
  Brick: obj.Brick || false,
  CementSiding: obj.CementSiding || false,
  LapSiding: obj.LapSiding || false,
  MetalSiding: obj.MetalSiding || false,
  VinylSiding: obj.VinylSiding || false,
  Shingle: obj.Shingle || false,
  Stone: obj.Stone || false,
  Stucco: obj.Stucco || false,
  Veneer: obj.Veneer || false,
  Wood: obj.Wood || false,
})

//ok
export const StyleOfHome = (obj = {}) => ({
  AFrame: obj.AFrame || false,
  Bungalow: obj.Bungalow || false,
  Colonial: obj.Colonial || false,
  Contemporary: obj.Contemporary || false,
  Cottage: obj.Cottage || false,
  Dome: obj.Dome || false,
  Log: obj.Log || false,
  Mediterranean: obj.Mediterranean || false,
  Ranch: obj.Ranch || false,
  Spanish: obj.Spanish || false,
  Tudor: obj.Tudor || false,
  Victorian: obj.Victorian || false,
});

//ok
export const Roof = (obj = {}) => ({
  CompositionShingle: obj.CompositionShingle || false,
  ConcreteTile: obj.ConcreteTile || false,
  Metal: obj.Metal || false,
  Rock: obj.Rock || false,
  Shake: obj.Shake || false,
  Slate: obj.Slate || false,
  Tar: obj.Tar || false,
  Tile: obj.Tile || false,
  Wood: obj.Wood || false,
})

// ok
export const Yard = (obj = {}) => ({
  SizeOfLot: obj.SizeOfLot || 0,
  SwimmingPool: obj.SwimmingPool || false,
  SportPool: obj.SportPool || false,
  Spa: obj.Spa || false,
  Sauna: obj.Sauna || false,
  SteamRoom: obj.SteamRoom || false,
  Fireplace: obj.Fireplace || false,
  FirePit: obj.FirePit || false,
  BuiltInBBQ: obj.BuiltInBBQ || false,
  OutdoorKitchen: obj.OutdoorKitchen || false,
  Courtyard: obj.Courtyard || false,
  PatioCovered: obj.PatioCovered || false,
  PationUncovered: obj.PationUncovered || false,
  Deck: obj.Deck || false,
  TennisCourts: obj.TennisCourts || false,
  TreesAndLandscaping: obj.TreesAndLandscaping || false,
  Gardens: obj.Gardens || false,
  Lawn: obj.Lawn || false,
  AutomaticSprinklers: obj.AutomaticSprinklers || false,
})

//ok
export const TypeOfHome = (obj = {}) => ({
  SingleFamily: obj.SingleFamily || false,
  Attached: obj.Attached || false,
  Detached: obj.Detached || false,
  Duplex: obj.Duplex || false,
  Halfplex: obj.Halfplex || false,
  Condo: obj.Condo || false,
  TownHome: obj.TownHome || false,
  ManufacturedHome: obj.ManufacturedHome || false,
})

export const TypeOfHomeFeatures = (obj = {}) => ({
  AgeBuilt: obj.AgeBuilt || 0,
  NumberOfBedrooms: obj.NumberOfBedrooms || 0,
  NumberOfBaths: obj.NumberOfBaths || 0,
  NumberOfStoriesOrLevels: obj.NumberOfStoriesOrLevels || 0,
  UtilitiesGas: obj.UtilitiesGas || 0,
  UtilitiesElectric: obj.UtilitiesElectric || 0,
  Parking: obj.Parking || 0,
  garage: obj.garage || 0,
  RVParking: obj.RVParking || 0,
  BoatStorage: obj.BoatStorage || 0,
});

export const HVAC = (obj = {}) => ({
  Floor: obj.Floor || false,
  Wall: obj.Wall || false,
  AirConditioning: obj.AirConditioning || false,
  CentralHeating: obj.CentralHeating || false,
  Propane: obj.Propane || false,
  Gravity: obj.Gravity || false,
})

//ok
export const TypeOfRooms = (obj = {}) => ({
  NumberOfMasterBedrooms: obj.NumberOfMasterBedrooms || 0,
  NumberOfJuniorMasters: obj.NumberOfJuniorMasters || 0,
  NumberOfEnsuites: obj.NumberOfEnsuites || 0,
  Loft: obj.Loft || 0,
  Den: obj.Den || 0,
  BonusRoom: obj.BonusRoom || 0,
  GreatRoom: obj.GreatRoom || 0,
  HomeOfficeArea: obj.HomeOfficeArea || 0,
  HomeTheater: obj.HomeTheater || 0,
  MediaRoom: obj.MediaRoom || 0,
  FamilyRoom: obj.FamilyRoom || 0,
  GymWorkoutRoom: obj.GymWorkoutRoom || 0,
  Library: obj.Library || 0,
  ButlerPantry: obj.ButlerPantry || 0,
  Sunroom: obj.Sunroom || 0,
  DownstairsBedroom: obj.DownstairsBedroom || 0,
  Basement: obj.Basement || 0,
  GuestQuarters: obj.GuestQuarters || 0,
  WineStorage: obj.WineStorage || 0,
})

//ok
export const Flooring = (obj = {}) => ({
  Carpeting: obj.Carpeting || false,
  Concrete: obj.Concrete || false,
  Bamboo: obj.Bamboo || false,
  Stone: obj.Stone || false,
  Tile: obj.Tile || false,
  Laminate: obj.Laminate || false,
  Cork: obj.Cork || false,
  VinylLinoleum: obj.VinylLinoleum || false,
  ManufacturedWood: obj.ManufacturedWood || false,
  Marble: obj.Marble || false,
  Wood: obj.Wood || false,
  WaterproofVinylPlanks: obj.WaterproofVinylPlanks || false,
})

//ok
export const KitchenAppliances = (obj = {}) => ({
  BuiltIn: obj.BuiltIn || false,
  Freestanding: obj.Freestanding || false,
  Stainless: obj.Stainless || false,
  Colored: obj.Colored || false,
  CabinetryMatch: obj.CabinetryMatch || false,
})

export const KitchenCounters = (obj = {}) => ({
  CastIron: obj.CastIron || false,
  Ceramic: obj.Ceramic || false,
  Granite: obj.Granite || false,
  LaminateOrSynthetic: obj.LaminateOrSynthetic || false,
  Marble: obj.Marble || false,
  OtherMaterial: obj.OtherMaterial || false,
  OtherMetal: obj.OtherMetal || false,
  Steel: obj.Steel || false,
  Stone: obj.Stone || false,
  Wood: obj.Wood || false,
})

export const KitchenFeatures = (obj = {}) => ({
  UnderCabinetLighting: obj.UnderCabinetLighting || false,
  RecessedLighting: obj.RecessedLighting || false,
  Pendants: obj.Pendants || false,
  DiningBars: obj.DiningBars || false,
  Islands: obj.Islands || false,
  WetBars: obj.WetBars || false,
  Remodeled: obj.Remodeled || false,
  WarmingDrawers: obj.WarmingDrawers || false,
  WineRefrigerators: obj.WineRefrigerators || false,
  TrashCompactors: obj.TrashCompactors || false,
  FarmStyleSinks: obj.FarmStyleSinks || false,
  DualSinks: obj.DualSinks || false,
  TripleSinks: obj.TripleSinks || false,
})

//ok
export const DiningFeatures = (obj = {}) => ({
  SpaceInKitchen: obj.SpaceInKitchen || false,
  BreakfasNook: obj.BreakfasNook || false,
  DiningCombination: obj.DiningCombination || false,
  LivingCombination: obj.LivingCombination || false,
  FormalDiningRoom: obj.FormalDiningRoom || false,
  OutdoorKitchen: obj.OutdoorKitchen || false,
})
//ok
export const MasterSuiteAmenities = (obj = {}) => ({
  Small: obj.Small || false,
  Large: obj.Large || false,
  Balcony: obj.Balcony || false,
  OutsideAccess: obj.OutsideAccess || false,
  PrivatePatio: obj.PrivatePatio || false,
  RemoteArea: obj.RemoteArea || false,
  SittingRoom: obj.SittingRoom || false,
  Office: obj.Office || false,
  GroundFloor: obj.GroundFloor || false,
  SecondFloor: obj.SecondFloor || false,
  SurroundSound: obj.SurroundSound || false,
  Fireplace: obj.Fireplace || false,
  WetBar: obj.WetBar || false,
  DualWalkInClosets: obj.DualWalkInClosets || false,
})
//ok
export const BathFeatures = (obj = {}) => ({
  DualSinks: obj.DualSinks || false,
  TripleSinks: obj.TripleSinks || false,
  BuiltInDressingVanities: obj.BuiltInDressingVanities || false,
  VanityShelves: obj.VanityShelves || false,
  MirrorsInShowers: obj.MirrorsInShowers || false,
  JettedOrSunkenTubs: obj.JettedOrSunkenTubs || false,
  SeparateShower: obj.SeparateShower || false,
  RainshoweHeads: obj.RainshoweHeads || false,
  StoneSurfaces: obj.StoneSurfaces || false,
  Tilesurfaces: obj.Tilesurfaces || false,
  OutsideAccess: obj.OutsideAccess || false,
  Skylights: obj.Skylights || false,
  HeatedFloors: obj.HeatedFloors || false,
  Remodeled: obj.Remodeled || false,
})

//ok
export const HomeEnergyAmenitiesAndFeatures = (obj = {}) => ({
  AtticFans: (obj && obj.AtticFans) || false,
  CeilingFans: (obj && obj.CeilingFans) || false,
  DualPaneWindows: (obj && obj.DualPaneWindows) || false,
  TriplePaneWindows: (obj && obj.TriplePaneWindows) || false,
  ProgrammableThermostats: (obj && obj.ProgrammableThermostats) || false,
  SingleFlushToilets: (obj && obj.SingleFlushToilets) || false,
  WindowShutters: (obj && obj.WindowShutters) || false,
  SolarHeat: (obj && obj.SolarHeat) || false,
  SolarPlumbing: (obj && obj.SolarPlumbing) || false,
  SolarScreens: (obj && obj.SolarScreens) || false,
  StormWindows: (obj && obj.StormWindows) || false,
  TanklessWaterHeater: (obj && obj.TanklessWaterHeater) || false,
  SkylightsOrSkyTubes: (obj && obj.SkylightsOrSkyTubes) || false,
  WholeHouseFan: (obj && obj.WholeHouseFan) || false,
})
//ok
export const AccessibilityFeatures = (obj = {}) => ({
  ExtraWideDoorways: (obj && obj.ExtraWideDoorways) || false,
  Ramps: (obj && obj.Ramps) || false,
  GrabBars: (obj && obj.GrabBars) || false,
  LowerCounterHeights: (obj && obj.LowerCounterHeights) || false,
  WalkInTubsAndShowers: (obj && obj.WalkInTubsAndShowers) || false,
})

export const PropertyDetails = (obj = {}) => ({
  Price: obj.Price || 1,
  Size: obj.Size || 1,
  SaleType: obj.SaleType || 1,
})

export const GeoLocation = (obj = {}) => ({
  TextAddress: obj.TextAddress || '',
  Lat: obj.Lat || '',
  Lng: obj.Lng || '',
})

export const BasicDetails = (obj = {}) => {
  const newObj = {
    Code: obj.Code || '',
    DateOfEntry: new Date().toISOString().substring(0, 10),
    Collaborators: obj.Collaborators || [],
    ShortDescription: obj.ShortDescription || '',
    LongDescription: obj.LongDescription || '',
  };

  try {
    if (obj.DateOfEntry) {
      newObj.DateOfEntry = new Date(obj.DateOfEntry).toISOString().substring(0, 10);
    }
  } catch (e) {
    newObj.DateOfEntry = new Date().toISOString().substring(0, 10)
  }
  return newObj;
}

