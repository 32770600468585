import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { EditFormContainer } from "../Containers/EditContainer";
import { GoogleMapEditContainer } from "../Containers/GoogleMapEditContainer";
import { BasicDetailsEditContainer, PropertyDetailsEditContainer } from "../Containers/CustomEditComponets";
import { FetchContainer } from "../Components/FetchContainer";
import { UIBtnSave } from "../Components/UIComponents";
import { saveOrUpdateListing } from "../api/ListingsAPI";
import { routerLinks } from "../helpers/links";
import { Schema } from "../helpers/Schema";
import { Row, Space, notification } from "antd";

const NavBarOptions = ({ editListing }) => {
  const navigate = useNavigate();
  return (
    <>
      <Space direction="horizontal" >
        <UIBtnSave onClick={() => {
          saveOrUpdateListing({ listing: editListing });
          navigate(routerLinks.search())
        }} />
      </Space>
    </>
  )
}

const Portal = ({ editListing }) =>
  document.getElementById('navPortal') && createPortal(
    <NavBarOptions editListing={editListing} />,
    document.getElementById('navPortal')
  )

export const EditListingPage = () => {
  const params = useParams();
  const [fetchParams, setFetchParams] = useState({});
  const editListing = useSelector(state => state.listings.editListing[params.listingId])
  const navigate = useNavigate();

  const fetchListing = () => {
    setFetchParams({
      type: 'get',
      listingId: params.listingId,
    })
  }

  const createNewListing = async () => {
    try {
      const response = await saveOrUpdateListing({ listing: Schema() });
      const listing = await response.json();
      if (listing.insertedId) {
        navigate(routerLinks.edit(listing.insertedId))
      }
    } catch (e) {
      console.log(e);
      notification.error({
        message: 'Could not create a new listing'
      })
    }
  }

  useEffect(() => {
    if (params.listingId !== 'new') {
      fetchListing({ listingId: params.listingId })
    } else {
      createNewListing()
    }
  }, [params.listingId]);

  return (
    <div>
      <FetchContainer params={fetchParams} />
      {
        editListing
          ?
          <div>
            <GoogleMapEditContainer listingId={params.listingId} />
            <Row gutter={[16, 16]}>
              <PropertyDetailsEditContainer listingId={params.listingId} withRow={false} />
              <BasicDetailsEditContainer listingId={params.listingId} />
            </Row>
            <EditFormContainer listingId={params.listingId} />
            <Portal editListing={editListing} />
          </div>
          : null
      }
    </div>
  )
}
export default EditListingPage;