import { useState, useEffect } from 'react';
import { Drawer, Button, Modal, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { SearchFormBuilder } from '../Components/SearchFormBuilder';
import { closeSearch, openSearch, resetSearchFilter, setMatchingMethod } from '../ReduxSlices/ListingsSlice';
import { UIBtnFilter, UIBtnSave, UIBtnSearch, UIBtnTrash } from '../Components/UIComponents';
import * as SchemaDefinitions from '../helpers/definitions';
import * as ListingsAPI from '../api/ListingsAPI';
import { DefaultSorting, DefaultSortingAutoFields } from '../helpers/Schema';

export const SearchFields = () => {
  const [definitions, setDefinitions] = useState([]);

  useEffect(() => {
    //definitions appear in order of appearence in the array
    setDefinitions(
      DefaultSortingAutoFields()
    )

  }, [])
  return (
    <>
      {definitions.map(definition =>
        <SearchFormBuilder
          key={definition}
          header={definition}
          groupId={definition}
          initializationFunction={SchemaDefinitions[definition]}
        />
      )}
    </>
  )
}

export const SearchListingsDrawer = ({ setListings }) => {
  const zIndex = 9999;
  const isOpen = useSelector(state => state.listings.openSearch);
  const searchFilter = useSelector(state => state.listings.searchFilter);
  const matchAll = useSelector(state => state.listings.searchFilter.matchAll);
  const dispatch = useDispatch();
  const [filtersOnStorage, getAvailableFilters] = useState([]);
  const [open, setOpen] = useState(false);
  const [filterName, setFilterName] = useState('');

  const showModal = () => setOpen(true);
  const hideModal = () => setOpen(false);

  const saveFilter = () => {
    setOpen(false);
    // try {
    //   localStorage.setItem(`fl_listings_${filterName}`, JSON.stringify(itemsToFilter));
    //   notification.success({ message: 'Your filter was successfully saved' });
    // } catch (e) {
    //   console.log(e);
    //   notification.error({ message: 'Could not save filter, maybe something wrong with the name ?' });
    // }
  }

  const getFilters = () => {
    for (const item in localStorage) {
      if (item.includes('fl_listings_')) {
        console.log(item);
      }
    }
  }

  const switchMatchingMethod = () => dispatch(setMatchingMethod(!matchAll))
  const resetFilters = () => dispatch(resetSearchFilter())
  const searchListings = () => {
    let nonNullKeys = Object.keys(searchFilter)
      .filter(key => (Object.keys(searchFilter[key]).length > 0))


    let itemsToFilter = [];

    nonNullKeys.forEach(key1 => {
      Object.keys(searchFilter[key1]).forEach(key2 => {
        itemsToFilter.push ({ [ `${key1}.${key2}` ]: searchFilter[key1][key2] });
      })
    })
 

    let filterClause;
    let itemsToFilter2 = {}

    if (matchAll) {
      filterClause = "$and"
    } else {
      filterClause = "$or"
    }

    if (itemsToFilter.length > 0) itemsToFilter2 = { [filterClause]: [...itemsToFilter] }

    ListingsAPI.searchListings({ filter: itemsToFilter2 })
      .then(r => r.ok && r.json())
      .then(data => {
        setListings(data);
        dispatch(closeSearch())
      })
  }
  return (
    <Drawer
      open={isOpen}
      placement="right"
      zIndex={zIndex}
      onClose={() => isOpen ? dispatch(closeSearch()) : dispatch(openSearch())}
      extra={
        <div>
          <Button.Group size="middle">
            <Button key="1" onClick={switchMatchingMethod}>
              {matchAll ? 'Match All' : 'Match some'}
            </Button>
            <UIBtnSearch key="2" onClick={searchListings} />
            <UIBtnTrash key="3" onClick={resetFilters} />
            <UIBtnFilter key="4" onClick={resetFilters} />
            <UIBtnSave key="5" onClick={showModal} />
          </Button.Group>
        </div>
      }
    >
      <SearchFields />
      <Modal
        title="Save your filter for future use"
        open={open}
        onOk={saveFilter}
        onCancel={hideModal}
        okText="OK"
        cancelText="Cancel"
        zIndex={zIndex + 1}
      >
        <p>Please enter a name that describes your filter</p>
        <Input type="text" onChange={e => setFilterName(e.target.value)} />
      </Modal>
    </Drawer>
  )
}