import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SearchListingsDrawer } from "../Containers/SearchListings";
import { routerLinks } from "../helpers/links";
import { useState } from "react";
import { DisplayLatestListings } from "../Containers/DisplayLatest";
import { Space } from "antd";
import { openSearch, resetSearchFilter } from "../ReduxSlices/ListingsSlice";
import { DisplaySearchResultsAsCards } from "../Components/DisplaySearchResultsAsCards";
import { DisplaySearchResultsAsTable } from '../Components/DisplaySearchResultsAsTable';
import { setTableOrCards } from '../../Layout/ReduxSlices/LayoutSlice';
import {
  UIBtnCard,
  UIBtnInsert,
  UIBtnSearch,
  UIBtnTable,
  UIBtnTrash,
} from '../Components/UIComponents.jsx';

import './SearchListingPage.css'

const NavBarOptions = () => {
  const tableOrCards = useSelector(state => state.layout.tableOrCards);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <Space direction="horizontal" >
        <UIBtnInsert onClick={() => navigate(routerLinks.edit('new'))} />
        <UIBtnSearch onClick={() => dispatch(openSearch())} />
        <UIBtnTrash onClick={() => dispatch(resetSearchFilter())} />
        {tableOrCards === 'table'
          ? <UIBtnCard onClick={() => dispatch(setTableOrCards('cards'))} />
          : <UIBtnTable onClick={() => dispatch(setTableOrCards('table'))} />
        }
      </Space>
    </>
  )
}

const Portal = () =>
  document.getElementById('navPortal') && createPortal(
    <NavBarOptions />,
    document.getElementById('navPortal')
  )

export const SearchPage = () => {
  const tableOrCards = useSelector(state => state.layout.tableOrCards);
  const [listings, setListings] = useState([]);

  return (
    <div className="container">
      <SearchListingsDrawer setListings={setListings} />
      <DisplayLatestListings setListings={setListings} />
      {tableOrCards === 'table'
        ? <DisplaySearchResultsAsTable listings={listings} />
        : <DisplaySearchResultsAsCards listings={listings} />
      }
      <Portal />
    </div>
  );
};
export default SearchPage;