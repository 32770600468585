import * as SchemaDefinitions from './definitions';

export const DefaultSorting = () => ([
  'BasicDetails',
  'PropertyDetails',
  'GeoLocation',
  'TypeOfHome',
  'MasterSuiteAmenities',
  'TypeOfRooms',
  'TypesOfLocations',
  'BathFeatures',
  'HomeConstruction',
  'HomeExterior',
  'StyleOfHome',
  'Roof',
  'Yard',
  'HVAC',
  'TypeOfHomeFeatures',
  'Flooring',
  'KitchenAppliances',
  'KitchenCounters',
  'KitchenFeatures',
  'DiningFeatures',
  'HomeEnergyAmenitiesAndFeatures',
  'AccessibilityFeatures',
])

export const DefaultSortingAutoFields = () => {
  const obj = [...DefaultSorting()];
  delete obj.BasicDetails;
  delete obj.PropertyDetails;
  delete obj.GeoLocation;

  return obj;
}

export const Schema = (obj = {}) => {
  const schema = {
    timestamps: {
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
    }
  };

  DefaultSorting().forEach(key =>
    schema[key] = SchemaDefinitions[key](obj[key])
  );

  if (obj._id) schema._id = obj._id;
  return schema;
};