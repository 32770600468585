import { useState, useEffect } from 'react';
import { Row, Col } from 'antd';

import { EditFormBuilder } from '../Components/EditFormBuilder';
import { DefaultSortingAutoFields } from '../helpers/Schema';
import * as SchemaDefinitions from '../helpers/definitions';

export const EditForm = ({ listingId }) => {
  const [definitions, setDefinitions] = useState([]);
  
  useEffect(() => {
    setDefinitions(
      DefaultSortingAutoFields()
    );
  }, [])
  
  return (
    <Row gutter={[16, 16]}>        
      {definitions.map(definition =>
        <Col xs={24} md={24} xl={12} xxl={6} key={definition}>
          <EditFormBuilder
            header={definition}
            listingId={listingId}
            groupId={definition}
            initializationFunction={SchemaDefinitions[definition]}
          />
        </Col>
      )}
    </Row>
  )
}

export const EditFormContainer = ({ listingId }) => 
  <EditForm listingId={listingId}/>
