import { Row, Col, Card } from "antd";
import { Link } from "react-router-dom";
import { routerLinks } from "../helpers/links";
import { FaEdit, FaTrash } from "react-icons/fa";
import { InsertOrSearch } from "./InsertOrSearch";

export const DisplaySearchResultsAsCards = ({ listings = []}) =>
  <Row gutter={[16, 16]}>
    <InsertOrSearch />
    {listings.length > 0 && listings.map(listing => (
      <Col
        key={listing._id}
        xs={24}
        sm={12}
        md={8}
        xl={6}
        xxl={4}
      >
        <Card
          title={listing._id}
          actions={[
            <Link to={routerLinks.edit(listing._id)}>
              <FaEdit />
            </Link>
            ,
            <FaTrash />,
            <FaTrash onClick={() => alert('a')} />
          ]}
        >
        </Card>
      </Col>
    ))
    }
  </Row>
