import { useState, useMemo } from "react";
import { Row, Col, Card, Space, Table, Tag, Button } from "antd";
import { Link } from "react-router-dom";
import { routerLinks } from "../helpers/links";
import { FaEdit, FaTrash } from "react-icons/fa";
import { UIBtnLinkEditListing, UIBtnFacebook, UIBtnTrash, UIBtnViber } from "./UIComponents";

const { Column, ColumnGroup } = Table;

export const DisplaySearchResultsAsTable = ({ listings = [] }) => {
  const [expanded, setExpanded] = useState(false);
  let data = [];

  if (listings.length > 0) {
    const data = listings.map(listing => {
      return {
        id: listing._id,
        code: listing.BasicDetails.Code,
        doe: new Date(listing.BasicDetails.DateOfEntry).toISOString().substring(0, 10),
        price: listing.PropertyDetails.Price,
        saleType: listing.PropertyDetails.SaleType,
        size: listing.PropertyDetails.Size
      };
    });
  }

  const columns = [
    { title: 'Name', dataIndex: 'code', key: 'name' },
    { title: 'doe', dataIndex: 'doe', key: 'doe' },
    { title: 'SaleType', dataIndex: 'saleType', key: 'SaleType' },
    { title: 'Size', dataIndex: 'Size', key: 'Size' },
    { title: 'Price', dataIndex: 'price', key: 'price', align: 'right' },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (_, record) => (
        <Space direction="horizontal">
          <UIBtnLinkEditListing listingId={record.id} />
          <UIBtnTrash onClick={() => alert('need to implement')} />
          <UIBtnFacebook onClick={() => alert('need to implement')} />
          <UIBtnViber onClick={() => alert('need to implement')} />
        </Space>
      )
    }
  ];

  const expandableProps = useMemo(() => {
    if (!expanded) {
      return undefined;
    }
    return {
      // columnWidth: 48,
      expandedRowRender: (record) => (
        <p
          style={{
            margin: 0,
          }}
        >
          🎉 Expanded {record.address1}
        </p>
      ),
      rowExpandable: true,
    };
  }, [expanded]);

  return (
    <Table
      style={{ width: '100%' }}
      rowKey="_id"
      onRow={(record, idx) => {
        console.log(record);
        return {
          onClick: e => console.log('clicked', record, idx, e)
        };
      }}
      columns={columns}
      dataSource={data}
      expandable={expandableProps}
    />
  );
};
